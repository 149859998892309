<template lang="pug">
div
  v-card(tile v-if="$slots.filters")
    v-card-title(v-if="title") {{title}}
    v-card-text
      v-row
        slot(name="filters")

  v-container(fluid)
    v-row
      v-col(cols="12" v-if="$slots.actions")
        slot(name="actions")

      v-col(cols="12")
        v-card
          slot(name="list")

      v-col(cols="12" v-if="$slots.footer")
        slot(name="footer")
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
