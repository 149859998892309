<template lang="pug">
ListPageLayout(title="Список работников")
  template(#filters)
    v-col(cols="12" sm="4" md="3")
      v-text-field(
        outlined
        label="Поиск работников"
        placeholder="Поиск по ФИО/табельному номер/телефону"
        append-icon="fa-search"
        :value="search"
        :disabled="$root.isDisabled('workers')"
        :clearable="search != null"
        hide-details
        @click:clear="querySearchList('')"
        @input="handleSearch"
        ref="search"
        dense
      )
    v-col(cols="12" sm="4" md="3")
      v-select(
        v-if="$can('READ_EMPLOYEES_DETAILED')"
        v-model="status"
        label="Статус работника"
        :items="statusItems"
        outlined
        hide-details
        item-value="value"
        item-text="name"
        :disabled="$root.isDisabled('workers')"
        dense
      )
    v-col(cols="12" sm="4" md="3")
      OrganizationPicker(
        v-model="orgId"
        :items="organizations"
        :disabled="$root.isDisabled('workers')"
        :multiple="false"
        canSelectAll
        outlined
        hide-details="auto"
        dense
      )
    v-col(cols="12" sm="12" md="3")
      v-btn.float-right(
        text
        @click="resetPageAndWorkers()"
        :disabled="$root.isDisabled('workers')"
      ) Сбросить фильтры

  template(#actions)
    v-btn.mr-6(
      v-if="$can('CREATE_EMPLOYEES')"
      @click="openEmployeeModal('create')"
      color="primary"
      depressed
    ) Добавить
    v-btn.mr-6(
      color="primary"
      v-if="$can('IMPORT_EMPLOYEES')"
      @click="openImportDialog"
      depressed
    ) Импортировать
    export-button(
      v-if="$can('EXPORT_ORGS')"
      :disabled="$root.isLoading('workers') || (total > 10000)"
      :query="query"
      :action="exportAction"
      fileNamePrefix="employees"
      :total="total"
    )

  template(#list)
    v-data-table(
      loading-text=""
      :headers="headers"
      :items="workers"
      must-sort
      :items-per-page.sync="limit"
      :sort-by.sync="order"
      :sort-desc.sync="orderType"
      :loading="$root.isLoading('workers')"
      no-data-text="Нет работников по данным фильтрам"
      hide-default-footer
    )

      template(#progress)
        Spinner

      template(#header)
        div.hidden-xs-only
          PerPagePicker(
            v-model="limit"
            data-table
          )

      template(#item.surname="{ item }")
        div.py-4
          div {{item.surname}} {{item.name}} {{item.patronymic}}
          span.text--disabled {{item.dateOfBirth && item.dateOfBirth.slice(0, 10) }}
      template(#item.personellNumber="{ item }")
        div {{item.personellNumber}}
        span.text--disabled {{item.status}}

      template(#item.actions="{ item }"  v-if="$can('READ_EMPLOYEES_DETAILED')")
        v-tooltip(left)
          template(v-slot:activator="{ on }")
            v-btn(
              v-on="on"
              icon
              :to="{ name: 'employees:card', params: { id: item.id } }"
            )
              v-icon(small) fa-eye
          span Просмотреть карточку работника
        v-tooltip(v-if="$can('UPDATE_EMPLOYEES')" left)
          template(v-slot:activator="{ on }")
            v-btn(
              icon
              @click="openEmployeeDialog(item)"
            )
              v-icon(small) fa-pencil-alt
          span Редактировать работника

  template(#footer)
    v-pagination(
      v-show="isShowPagination"
      v-model="currentPage"
      :length="totalPages"
      total-visible="10"
    )
</template>

<script>
import ListPageLayout from '@/components/layouts/ListPageLayout';
import OrganizationPicker from '@/components/controls/OrganizationPicker.vue';
import Spinner from '@/components/Spinner.vue';
import ExportButton from '@/components/ExportButton';
import PerPagePicker from '@/components/controls/PerPagePicker';

import { debounce } from 'lodash';

import { mapActions, mapGetters } from 'vuex';
import { mapGettersMutations } from '@/utils/vuex';
import { STATUS } from '@/utils/constants.js';

export default {
  components: {
    ListPageLayout,
    OrganizationPicker,
    Spinner,
    ExportButton,
    PerPagePicker,
  },
  props: {
    openImportDialog: {
      type: Function,
      default: () => {},
    },
    openEmployeeModal: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      statusItems: STATUS,
      headers: [
        { name: 'id', text: 'ID', value: 'id', width: '150px' },
        { value: 'surname', text: 'ФИО', width: '200px' },
        {
          text: 'Табельный номер',
          value: 'personellNumber',
          sortable: false,
          width: '200px',
        },
        {
          text: 'Номер телефона',
          value: 'phone',
          sortable: false,
          width: '200px',
        },
        { text: 'Пол', value: 'gender', sortable: false, width: '50px' },
        { text: 'Организация', value: 'organization', width: '200px' },
        { value: 'actions', align: 'right', sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(['organizations']),

    ...mapGetters('EMPLOYEE_LIST', [
      'items',
      'total',
      'totalPages',
      'isShowPagination',
      'query',
      'search',
    ]),

    ...mapGettersMutations('EMPLOYEE_LIST', {
      orgId: 'orgId',
      status: 'status',
      currentPage: 'currentPage',
      limit: 'limit',
      order: 'orderBy',
      orderType: 'orderType',
    }),

    workers() {
      return this.$root.isLoading('workers') ? [] : this.items;
    },

    exportAction() {
      return 'EMPLOYEE_LIST/export';
    },
  },

  watch: {
    total(val) {
      this.$notify({
        group: 'note',
        type: 'info',
        title: `Найдено работников: ${val}`,
        text: '',
      });
    },
  },

  methods: {
    ...mapActions('EMPLOYEE_LIST', ['fetchList', 'querySearchList']),
    ...mapActions('EMPLOYEE', ['resetItem', 'setEmployee']),

    handleSearch: debounce.call(
      this,
      function (value) {
        // если это телефон, то убираем скобочки и пр.
        if (/^[0-9+()\s-]+$/.test(value)) {
          value = value.replace(/[+()\s-]/g, '');
        }
        this.querySearchList(value)
          .catch(() => {
            this.$notify({
              group: 'error',
              type: 'error',
              title: `Произошла ошибка загрузки работников. Попробуйте позже.`,
              text: '',
            });
          })
          .finally(() => this.$refs.search.focus());
      },
      1000,
    ),

    async openEmployeeDialog({ id }) {
      await this.setEmployee(id);
      this.openEmployeeModal('update');
    },

    resetPageAndWorkers() {
      this.$store.commit('EMPLOYEE_LIST/resetFilters');
    },
  },
};
</script>
