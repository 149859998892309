<template lang="pug">
  div.d-flex.justify-center.flex-column.align-center.my-5
    v-progress-circular(
      indeterminate
      :size="size"
      :width="lineWidth"
      :color="color"
    )
    div.mt-3(:class="`${color}--text`" v-if="withTitle") {{ title }}
</template>

<script>
export default {
  props: {
    withTitle: {
      type: Boolean,
      default: true,
    },

    color: {
      type: String,
      default: 'primary',
    },

    lineWidth: {
      type: Number,
      default: 2,
    },

    size: {
      type: Number,
      default: 60,
    },
    title: {
      type: String,
      default: 'Загрузка',
    },
  },
};
</script>
