function normalizeNamespace(fn) {
  return (namespace, ...args) => {
    if (typeof namespace !== 'string') {
      args[0] = namespace;
      namespace = '';
    } else if (namespace.charAt(namespace.length - 1) !== '/') {
      namespace += '/';
    }
    return fn(namespace, ...args);
  };
}

function isObject(obj) {
  return obj !== null && typeof obj === 'object';
}

export const mapGettersMutations = normalizeNamespace((namespace, map) => {
  const res = {};
  if (!isObject(map)) {
    console.error('mapGettersMutations: mapper parameter must be an Object');
  }

  Object.entries(map).forEach(([getterName, mutationName]) => {
    res[getterName] = {
      get() {
        let getters = this.$store.getters;

        if (namespace) {
          const module = this.$store._modulesNamespaceMap[namespace];
          if (!module) {
            return;
          }
          getters = module.context.getters;
        }

        return getters[getterName];
      },
      set(...args) {
        let commit = this.$store.commit;

        if (namespace) {
          const module = this.$store._modulesNamespaceMap[namespace];
          if (!module) {
            return;
          }
          commit = module.context.commit;
        }

        return commit.apply(this.$store, [mutationName].concat(args));
      },
    };
    // mark vuex getter for devtools
    res[getterName].vuex = true;
  });

  return res;
});
