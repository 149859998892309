<template lang="pug">
  v-autocomplete(
    ref="select"
    :value="value"
    @input="handleChange"
    placeholder="Выберите организацию"
    label="Организация"
    :loading="isLoading"
    :items="innerItems"
    :disabled="disabled"
    :counter="multiple ? maxSelected : null"
    :error-messages="(isShowError ? 'Выберите хотя бы одну организацию' : null) || errorMessages"
    :multiple="multiple"
    :hide-details="hideDetails"
    :outlined="outlined"
    :dense="dense"
    item-value="id"
    item-text="name"
  )
    template(v-slot:prepend-item v-if="multiple && canSelectAll")
      v-list-item(@click="toggleAll")
        v-list-item-action()
          v-simple-checkbox(
            @input="toggleAll"
            :value="selectedCount != 0"
            :indeterminate="!selectedCount ? false : !isAllItemsSelected"
          )
        v-list-item-content
          v-list-item-title Выбрать все организации
    template(#selection="{ item, index }" )
      div(
        v-if="index === 0"
        class="v-select__selection v-select__selection--comma"
      )
        span(v-if="isAllItemsSelected") Все организации
        span(v-else) {{ item.name }}
      span(
          v-else-if="!isAllItemsSelected && index === 1"
          class="grey--text caption"
      ) (+ еще {{ value.length - 1 }})

</template>

<script>
export default {
  props: {
    value: {
      type: [Array, Number],
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
    multiple: Boolean,
    canSelectAll: Boolean,
    maxSelected: {
      type: Number,
      default: () => null,
    },
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },

    // look params
    hideDetails: {
      type: [Boolean, String],
      default: 'auto',
    },
    dense: Boolean,
    outlined: {
      type: String,
      default: null,
    },
  },
  computed: {
    isLoading() {
      return this.items === null;
    },
    innerItems() {
      if (!this.multiple && this.canSelectAll)
        return [{ id: null, name: 'Все организации' }, ...this.items];
      return this.items;
    },
    selectedCount() {
      return this.value?.length || 0;
    },
    isAllItemsSelected() {
      return this.value?.length === this.items?.length;
    },
    isShowError() {
      // FIXME: drop logic of validation to parent's component
      // if not loading
      // and if not multiple
      // and if value not eq null
      // and if zero items selected
      return (
        !this.isLoading &&
        this.multiple &&
        this.value != null &&
        this.selectedCount === 0
      );
    },
  },
  methods: {
    toggleAll() {
      this.$nextTick(() => {
        if (!this.multiple) return;

        if (this.isAllItemsSelected) this.handleChange([]);
        else this.handleChange(this.items.map(({ id }) => id));
      });
    },

    handleChange(value) {
      this.$emit('input', value);
    },
  },
};
</script>
