<template lang="pug">
  v-menu( bottom :nudge-bottom="36")
    template(v-slot:activator="{ on }")
      v-btn(
        :disabled="disabled"
        v-on="on"
        color="primary"
        depressed
      ) Экспортировать
    v-list
      v-list-item(
        v-for="option in exportOptions"
        :key="option.format"
        @click="executeExport(option.type, option.ext)"
      )
        v-list-item-title {{option.name}}
</template>

<script>
import { DateTime } from 'luxon';

const EXPORT_OPTIONS = [
  { name: 'Как PDF', type: 'PDF', ext: 'pdf' },
  { name: 'Как Excel', type: 'EXCEL', ext: 'xlsx' },
];

export default {
  props: {
    disabled: Boolean,
    query: {
      type: Object,
      default: () => ({}),
    },
    action: {
      type: String,
      default: null,
    },
    fileNamePrefix: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      exportOptions: EXPORT_OPTIONS,
    };
  },
  computed: {},
  methods: {
    executeExport(format, ext) {
      const dt = DateTime.local();
      const fileName =
        this.fileNamePrefix + '_' + dt.toFormat('HHmm_ddLLyyyy') + '.' + ext;
      const options = JSON.stringify({
        query: { ...this.query, format },
        action: this.action,
        fileName,
      });
      const win = window.open('/export');
      win.exportOptions = options;
    },
  },
};
</script>
