<template lang="pug">
div.d-flex.pl-1(
  style="background-color: white;"
  :class="{'per-page-picker--data-table': dataTable}"
)
  div.text-caption.text--disabled.pr-2.align-self-center {{label}}
  v-select(
    dense
    outlined
    hide-details
    :items="items"
    :value="value"
    @input="$emit('input', $event)"
    style="width: 100px;"
  )
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    label: {
      default: 'Показывать на странице',
      type: String,
    },
    items: {
      default: () => [30, 50, 100],
      type: Array,
    },
    dataTable: Boolean,
  },
};
</script>

<style scoped>
.per-page-picker--data-table {
  position: absolute;
  top: 3px;
  right: 3px;
}
</style>
